import React, { useState } from "react";
import logo from "../../Assets/logo.svg";
import "./nav.scss";
import { MenuOutlined } from "@ant-design/icons";
import DrawerComponent from "../Drawer/DrawerComponent";
const Navbar = ({ onMenuClick, refs }) => {
  const [openMenu, setOpenMenu] = useState(false);
  return (
    <div className="navbar">
      <div className="navbar_logo">
        <img src={logo} width={250} height={250} />
      </div>
      <div className="navbar_hamburger">
        <MenuOutlined onClick={() => setOpenMenu(true)} />
      </div>
      <DrawerComponent
        position={"right"}
        open={openMenu}
        close={() => setOpenMenu(false)}
        onMenuClick={onMenuClick}
        refs={refs}
      />
    </div>
  );
};

export default Navbar;
