// DrawerComponent.js
import React from "react";
import { Drawer, Row } from "antd";
import { CloseOutlined } from "@ant-design/icons";

const DrawerComponent = ({ position, open, close, onMenuClick, refs }) => {
  const commomStyles = {
    fontSize: "35px",
    margin: "40px 10px",
    fontWeight: "600",
    cursor: "pointer",
  };
  const commomStylesLabel = {
    fontSize: "35px",
    margin: "40px 10px",
    fontWeight: "600",
    opacity: 0.5,
  };
  const width = window.innerWidth;

  const justScroll = (refInfo) => {
    close();
    onMenuClick(refInfo);
  };

  return (
    <Drawer
      placement={position}
      closable={false}
      open={open}
      key={position}
      width={width < 600 ? "100%" : "50%"}
    >
      <div
        style={{
          float: "right",
          fontWeight: "700",
          fontSize: 20,
          cursor: "pointer",
        }}
        onClick={() => close()}
      >
        <CloseOutlined />
      </div>
      <Row style={commomStylesLabel}>
        <span style={{ color: "#366B35" }}>Screens</span>
      </Row>

      <Row
        style={commomStyles}
        onClick={() => justScroll(refs?.heroThirdPageRef)}
      >
        <span>Previous Projects</span>
      </Row>
      <Row style={commomStyles} onClick={() => justScroll(refs.footerRef)}>
        <span>Contact Us</span>
      </Row>
      <Row
        style={commomStyles}
        onClick={() => justScroll(refs.heroSecondPageRef)}
      >
        <span>Why Us</span>
      </Row>
      <Row style={commomStylesLabel}>
        <span style={{ color: "#366B35" }}>Links</span>
      </Row>
      <Row style={commomStyles}>
        <a
          href="https://www.google.com/maps/place/14%C2%B028'43.3%22N+78%C2%B049'31.5%22E/@14.4786903,78.8247593,19z/data=!3m1!4b1!4m12!1m7!3m6!1s0x3bb3721c9fd33879:0x2e5a2678927205e6!2sS.A.H+Clinic!8m2!3d14.478916!4d78.8253839!16s%2Fg%2F11bxbvpywm!3m3!8m2!3d14.478689!4d78.825403?entry=ttu"
          target="_blank"
          style={{ color: "black" }}
        >
          Location
        </a>
      </Row>
      <Row style={commomStyles}>
        <a
          href="https://www.youtube.com/@SIBESTATES"
          target="_blank"
          style={{ color: "black" }}
        >
          Youtube
        </a>
      </Row>
      <Row style={commomStyles}>
        <a href="mailto:sibconstructions@gmail.com" style={{ color: "black" }}>
          Email
        </a>
      </Row>
      <Row style={commomStyles}>
        <a
          href="https://www.instagram.com/sibestates"
          target="_blank"
          style={{ color: "black" }}
        >
          Instagram
        </a>
      </Row>
    </Drawer>
  );
};

export default DrawerComponent;
