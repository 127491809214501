import React, { forwardRef } from "react";
import "./footer.scss";
import { Card, Col, Row } from "antd";

const Footer = forwardRef(({ forwardedRefFour, onMenuClick, refs }) => {
  return (
    <div ref={forwardedRefFour}>
      <h1>{`Connect With Us`}</h1>
      <Row
        gutter={50}
        style={{
          marginBottom: "30px",
          padding: 10,
        }}
      >
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15452.414517554984!2d78.825433!3d14.4787384!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bb373019c430c55%3A0x4cbd35a1534f9887!2sSIB%20ESTATES!5e0!3m2!1sen!2sin!4v1702305863032!5m2!1sen!2sin"
            width={"100%"}
            height="250"
            style={{ border: 0, borderRadius: "20px" }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </Col>

        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={4}
          style={{ marginTop: "-40px" }}
        >
          <h3>Services We Offer</h3>
          <Row justify={"center"} style={{ margin: "10px" }}>
            Plan Approval
          </Row>
          <Row justify={"center"} style={{ margin: "10px" }}>
            2D/3D Designing
          </Row>
          <Row justify={"center"} style={{ margin: "10px" }}>
            Construction
          </Row>
          <Row justify={"center"} style={{ margin: "10px" }}>
            Renovation
          </Row>
          <Row justify={"center"} style={{ margin: "10px" }}>
            & More
          </Row>
        </Col>

        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={4}
          style={{ marginTop: "-40px" }}
        >
          <h3>Connect</h3>
          <Row justify={"center"} style={{ margin: "10px" }}>
            <a
              href="https://www.instagram.com/sibestates"
              class="c-link"
              target="_blank"
            >
              Instagram
            </a>
          </Row>
          <Row justify={"center"} style={{ margin: "10px" }}>
            <a
              href="https://www.youtube.com/@SIBESTATES"
              class="c-link"
              target="_blank"
            >
              Youtube
            </a>
          </Row>
          <Row justify={"center"} style={{ margin: "10px" }}>
            <a
              href="https://www.google.com/maps/place/14%C2%B028'43.3%22N+78%C2%B049'31.5%22E/@14.4786903,78.8247593,19z/data=!3m1!4b1!4m12!1m7!3m6!1s0x3bb3721c9fd33879:0x2e5a2678927205e6!2sS.A.H+Clinic!8m2!3d14.478916!4d78.8253839!16s%2Fg%2F11bxbvpywm!3m3!8m2!3d14.478689!4d78.825403?entry=ttu"
              target="_blank"
            >
              Location
            </a>
          </Row>
          <Row justify={"center"} style={{ margin: "10px" }}>
            <a href="tel:+918125884144" class="c-link">
              Contact
            </a>
          </Row>
          <Row justify={"center"} style={{ margin: "10px" }}>
            <a href="mailto:sibconstructions@gmail.com" class="c-link">
              Email
            </a>
          </Row>
        </Col>

        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={4}
          style={{ marginTop: "-40px" }}
        >
          <h3>Menu</h3>
          <Row justify={"center"} style={{ margin: "10px" }}>
            <a onClick={() => onMenuClick(refs.heroRef)} class="c-link">
              Home
            </a>
          </Row>
          <Row justify={"center"} style={{ margin: "10px" }}>
            <a
              onClick={() => onMenuClick(refs.heroSecondPageRef)}
              class="c-link"
            >
              Why Us
            </a>
          </Row>
          <Row justify={"center"} style={{ margin: "10px" }}>
            <a
              onClick={() => onMenuClick(refs.heroThirdPageRef)}
              class="c-link"
            >
              Previous Projects
            </a>
          </Row>
        </Col>
      </Row>
    </div>
  );
});

export default Footer;
