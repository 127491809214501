import { Card, Col, Row } from "antd";
import React, { forwardRef } from "react";
import { heroSecondDetails } from "../../Helper/constants";

const HeroSecondPage = forwardRef(({ forwardedRefSecond }) => {
  const { image, title, features } = heroSecondDetails;
  return (
    <div ref={forwardedRefSecond}>
      <h1>{`Why Us`}</h1>
      <Card
        bordered={false}
        className="hero_second_main"
        style={{ boxShadow: "none" }}
        bodyStyle={{ padding: 0 }}
      >
        <Row justify="space-evenly">
          <Col xs={24} sm={24} md={10} lg={10} xl={10}>
            <div className="hero_second_title">{title}</div>
            <br />
            <div className="hero_second_features">
              <Row gutter={[5, 5]} justify="space-between">
                {features.map((e, index) => (
                  <Col key={index} xs={24} sm={24} md={24} lg={24} xl={7}>
                    <div>
                      <label className="value">{e.value}</label>
                      <br />
                      <label className="context">{e.description}</label>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </Col>
          <Col xs={24} sm={24} md={14} lg={14} xl={14}>
            <img className="hero_second_image" src={image} alt="Hero Image" />
          </Col>
        </Row>
      </Card>
      <br />
      <br />
    </div>
  );
});

export default HeroSecondPage;
