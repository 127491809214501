import { useEffect, useRef } from "react";
import "./App.css";
import Footer from "./Components/Footer/footer";
import Hero from "./Components/Main/hero";
import HeroSecondPage from "./Components/Main/heroSecondPage";
import HeroThirdPage from "./Components/Main/heroThirdpage";
import Navbar from "./Components/Nav/navbar";

const App = () => {
  const heroRef = useRef(null);
  const heroSecondPageRef = useRef(null);
  const heroThirdPageRef = useRef(null);
  const footerRef = useRef(null);
  const scrollToSection = (ref) => {
    console.log("Scrolling to section", ref);
    window.scrollTo({
      top: ref.current.offsetTop,
      behavior: "smooth",
    });
  };

  const handleMenuClick = (sectionRef) => {
    scrollToSection(sectionRef);
  };

  return (
    <div className="App">
      <Navbar
        onMenuClick={handleMenuClick}
        refs={{
          heroRef: heroRef,
          heroSecondPageRef: heroSecondPageRef,
          heroThirdPageRef: heroThirdPageRef,
          footerRef: footerRef,
        }}
      />
      <Hero forwardedRef={heroRef} />
      <HeroSecondPage forwardedRefSecond={heroSecondPageRef} />
      <HeroThirdPage forwardedRefThird={heroThirdPageRef} />
      <Footer
        forwardedRefFour={footerRef}
        onMenuClick={handleMenuClick}
        refs={{
          heroRef: heroRef,
          heroSecondPageRef: heroSecondPageRef,
          heroThirdPageRef: heroThirdPageRef,
          footerRef: footerRef,
        }}
      />
    </div>
  );
};

export default App;
