import React, { forwardRef } from "react";
import "./hero.scss";
import { heroDetails } from "../../Helper/constants";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Col, Row } from "antd";

const Hero = forwardRef(({ forwardedRef }) => {
  const { image, title, sqft, expected, about, titleMore } = heroDetails;
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const width = window.innerWidth;
  return (
    <div className="main" ref={forwardedRef}>
      <div className="hero_tagname">
        {title} <span>{titleMore}</span>
      </div>
      <div className="hero_about">
        <span>
          <b>{`ABOUT :`}</b>
          <br />
          <div className="hero_aboutDetails">{about}</div>
        </span>
      </div>
      <img
        className="hero_img"
        src={image}
        style={{ height: width < 600 ? "30rem" : "40rem" }}
      />
      <Row className="hero_est" justify="space-between">
        <Col className="hero_text">
          {`Total Area`}
          <br />
          <span>{sqft}</span>
        </Col>
        <Col className="hero_text">
          {`Expected Completion`}
          <br />
          <span>{expected}</span>
        </Col>
      </Row>
      <div className="hero_scrollup" onClick={() => scrollToTop()}>
        <KeyboardArrowUpIcon />
      </div>
      <br />
      <br />
    </div>
  );
});

export default Hero;
