import React, { forwardRef, useState } from "react";
import { Card, Col, Modal, Row } from "antd";
import { heroThirdDetails } from "../../Helper/constants";

const HeroThirdPage = forwardRef(({ forwardedRefThird }) => {
  const { projects } = heroThirdDetails;
  const [state, setState] = useState(0);
  const mobileScreen = window.innerWidth;
  const displayWithIndex = () => {
    if (state >= 0 && !projects.length <= state) {
      return [projects[state]];
    } else return [projects[0]];
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <div ref={forwardedRefThird}>
      <h1>{`Our Previous Projects`}</h1>
      {displayWithIndex().map((e, index) => {
        return (
          <Card
            key={index}
            bordered={false}
            className="hero_second_main"
            style={{ boxShadow: "none" }}
            bodyStyle={{ padding: 0 }}
          >
            <Row justify="space-evenly" gutter={[40]}>
              <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                <img
                  loading="lazy"
                  className="hero_second_image"
                  src={e?.image}
                />
              </Col>
              <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                <div className="hero_third_features">
                  <div className="name">{e.Name},</div>
                  <div className="name">{e.Address},</div>
                  <div className="context">{e.description}</div>
                  <div
                    onClick={showModal}
                    className="name_underline"
                  >{`More Details`}</div>
                </div>
              </Col>
            </Row>
          </Card>
        );
      })}
      {displayWithIndex()?.length > 1 ? (
        <div className="hero_third_scroller">
          <button
            disabled={state <= 0}
            className={state <= 0 ? "exceeded" : ""}
            onClick={() => setState(state - 1)}
          >{`<`}</button>
          &nbsp;&nbsp;&nbsp;
          <button
            disabled={projects.length === state + 1}
            className={projects.length === state + 1 ? "exceeded" : ""}
            onClick={() => setState(state + 1)}
          >{`>`}</button>
        </div>
      ) : (
        ""
      )}
      <br />
      <br />
      <Modal
        style={{ marginTop: "-5rem" }}
        title={displayWithIndex()?.[0]?.Name}
        open={isModalOpen}
        centered
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        width={"99%"}
        height={"90%"}
      >
        <img
          className="hero_third_image"
          src={displayWithIndex()?.[0]?.image}
        />
        <br />
        <br />
        {displayWithIndex()?.[0]?.moreInfo}
      </Modal>
    </div>
  );
});

export default HeroThirdPage;
